<template>
  <div class="body-container">
    <Spinner />
    <div v-show="!mobile" id="nav">
      <div 
      class="navbar"
      @mouseover="navFade"
      >
        <router-link class="navbar__link" to="/" @mouseleave="navFullColor"
        >Home
        </router-link>
        <router-link class="navbar__link" to="/about" @mouseleave="navFullColor"
        >About
        </router-link>
        <router-link class="navbar__link" to="/work" @mouseleave="navFullColor"
        >Work
        </router-link>
        <router-link class="navbar__link" to="/contact" @mouseleave="navFullColor"
        >Contact
        </router-link>
      </div>
    </div>
    <div class="header-content-container">
      <!-- <img
        v-if="this.$route.path === '/'"
        v-bind:src="require('@/assets/images/sitting-by-tree-mask-back.png')"
        alt="playing guitar inverted color"
        class="home__headerImage headerImage mask-img"
      />
      <img
        v-if="this.$route.path === '/'"
        v-bind:src="require('@/assets/images/sitting-by-tree-mask.png')"
        alt="playing guitar inverted color"
        class="home__headerImage headerImage top-mask-img"
      /> -->

      <img
        v-bind:src="require('@/assets/images/sitting-by-tree-mask-back.png')"
        alt="picture of Dehsen Ragavulu"
        class="home__headerImage headerImage mask-img"
      />
      <img
        v-bind:src="require('@/assets/images/sitting-by-tree-mask.png')"
        alt="picture of Dehsen Ragavulu"
        class="home__headerImage headerImage top-mask-img"
      />

      <!-- <img
        v-if="this.$route.path === '/'"
        :srcset="`${headerImages.homeMobile} 374w, ${headerImages.home}339w`"
        sizes="(max-width: 545px) 374px, 339px"
        alt="playing guitar inverted color"
        class="home__headerImage headerImage"
      /> -->
      <!-- <img
        v-if="this.$route.path === '/about'"
        v-bind:src="require('@/assets/images/playing-guitar-inverted.jpg')"
        alt="playing guitar inverted color"
        class="about__headerImage headerImage"
      />
      <img
        v-if="this.$route.path === '/work'"
        v-bind:src="require('@/assets/images/sitting-by-tree.png')"
        alt="playing guitar inverted color"
        class="work__headerImage headerImage"
      />
      <img
        v-if="this.$route.path === '/contact'"
        v-bind:src="require('@/assets/images/river.jpg')"
        alt="river"
        class="contact__headerImage headerImage"
      /> -->
      <transition-group name="layout" tag="div" appear>
        <div class="headshot__container" key="headshot-container">
          <img
            v-bind:src="require('@/assets/images/headshot.png')"
            alt="headshot picture of Dehsen"
            class="headshot-image item-image"
            key="headshot"
          />
          <a
            href="#"
            class="hire-btn item-btn"
            key="headshot-btn"
            @click.prevent="contact"
            >Hire Me</a
          >
          <h2 class="my-name item-name" key="headshot-firstName">
            Dehsen Ragavulu
          </h2>
        </div>
      </transition-group>
    </div>

    <button
      class="hamburger hamburger--collapse"
      type="button"
      @click="toggleMobileNav"
      v-show="mobile"
      :class="{ 'is-active': mobileNav }"
    >
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>

    <transition name="mobile-nav">
      <ul v-show="mobileNav" class="dropdown-nav">
        <li class="dropdown-nav__list-item">
          <router-link
            class="dropdown-nav__link"
            to="/"
            @click="toggleMobileNav"
            >Home</router-link
          >
        </li>
        <li class="dropdown-nav__list-item">
          <router-link
            class="dropdown-nav__link"
            to="/about"
            @click="toggleMobileNav"
            >About</router-link
          >
        </li>
        <li class="dropdown-nav__list-item">
          <router-link
            class="dropdown-nav__link"
            to="/work"
            @click="toggleMobileNav"
            >Work</router-link
          >
        </li>
        <li class="dropdown-nav__list-item">
          <router-link
            class="dropdown-nav__link"
            to="/contact"
            @click="toggleMobileNav"
            >Contact</router-link
          >
        </li>
      </ul>
    </transition>

    <!-- <transition-group name="layout" tag="div" appear>
      <div class="headshot__container" key="headshot-container">
        <img
          v-bind:src="require('@/assets/images/headshot.png')"
          alt="headshot picture of Dehsen"
          class="headshot-image items"
          key="headshot"
        />
        <a href="#" class="hire-btn items" key="headshot-btn">Hire Me</a>
        <h2 class="my-name items" key="headshot-firstName">Dehsen Ragavulu</h2>
      </div>
    </transition-group> -->

    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component"></component>
      </transition>
    </router-view>

    <footer class="footer">
      <div class="footer__social-container">
        <ul class="footer__social-ul">
          <!-- <li class="footer__social">
            <a
              href="https://www.behance.net/lccdehsen96d0"
              target="_blank"
              rel="noreferrer"
              class="footer__social-link"
              aria-label="behance"
            >
              <svg
                height="41"
                viewBox="0 0 176 176"
                width="41"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="behance">
                    <rect
                      id="background"
                      fill="#1d70fa"
                      height="176"
                      rx="24"
                      width="176"
                    />
                    <g id="icon" fill="#fff">
                      <path d="m104.46 57.96h27.57v6.86h-27.57z" />
                      <path
                        d="m143 96.51c0-14.32-11.09-25.93-24.76-25.93s-24.76 11.61-24.76 25.93 10.29 25.92 24.76 25.92c11 0 20.4-4.84 23.59-16.46h-11.61a12.45 12.45 0 0 1 -11 6.57c-6.49 0-12.2-4.65-11.86-12.47h35.45c.19-1.14.19-2.32.19-3.56zm-35.6-5.09c.86-6.44 5.41-10.55 10.9-10.55s10 4.11 10.9 10.55z"
                      />
                      <path
                        d="m89.24 97.6c-.9-9.94-12.14-13.29-12.14-13.29 4.27-2 9.36-6.09 9.31-15.1-.08-13-13.64-15.64-18-15.64h-35.41v67.62h32.17c4.47 0 9.23-.59 13-2.47 10.17-5 11.95-11.17 11.07-21.12zm-41.36-32.36h15.22s9-.21 9 7.75-9 7.74-9 7.74h-15.22zm16.12 44.47h-16.12v-18.49h16.12s10.72-.25 10.72 9.24-10.72 9.25-10.72 9.25z"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </a>
          </li> -->
          <!-- <li class="footer__social">
            <a
              href="https://codepen.io/laptopsoup1"
              class="footer__social-link"
              target="_blank"
              rel="noreferrer"
              aria-label="codepen"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xmlns:svgjs="http://svgjs.com/svgjs"
                version="1.1"
                width="41"
                height="41"
                x="0"
                y="0"
                viewBox="0 0 24 24"
                style="enable-background: new 0 0 512 512"
                xml:space="preserve"
                class=""
              >
                <g>
                  <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="m24 7.598c-.044-.264-.08-.47-.36-.641-11.449-6.791-11.287-7.297-12.03-6.848-8.677 5.394-11.092 6.597-11.439 7.017-.315.323-.171.818-.171 8.298-.021.851 7.743 5.462 11.519 8.404.333.237.752.199 1.003-.029 11.224-7.956 11.497-7.636 11.478-8.375 0 0-.012-7.927 0-7.826zm-1.5 6.491-3.876-2.359 3.876-2.697zm-5.277-3.212-4.473-2.722v-6.07l9.126 5.555zm-5.223 3.633-3.876-2.697 3.876-2.359 3.876 2.359zm-.75-12.426v6.074c-1.739 1.079-3.209 1.98-4.451 2.734l-4.675-3.252zm-5.857 9.658c-1.874 1.127-3.098 1.843-3.893 2.32v-5.029zm1.33.924 4.527 3.149v5.999l-9.126-6.349zm6.027 9.149v-5.999l4.527-3.149 4.599 2.799z"
                    fill="#ffffff"
                    data-original="#000000"
                    style=""
                    class=""
                  />
                </g>
              </svg>
            </a>
          </li> -->
          <li class="footer__social">
            <a
              href="https://www.linkedin.com/in/dehsen-ragavulu-57b93916a"
              class="footer__social-link"
              target="_blank"
              rel="noreferrer"
              aria-label="LinkedIn"
            >
              <svg
                height="41"
                viewBox="0 0 176 176"
                width="41"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="linkedin">
                    <rect
                      id="background"
                      fill="#0077b5"
                      height="176"
                      rx="24"
                      width="176"
                    />
                    <g id="icon" fill="#fff">
                      <path
                        d="m63.4 48a15 15 0 1 1 -15-15 15 15 0 0 1 15 15z"
                      />
                      <path
                        d="m60 73v66.27a3.71 3.71 0 0 1 -3.71 3.73h-15.81a3.71 3.71 0 0 1 -3.72-3.72v-66.28a3.72 3.72 0 0 1 3.72-3.72h15.81a3.72 3.72 0 0 1 3.71 3.72z"
                      />
                      <path
                        d="m142.64 107.5v32.08a3.41 3.41 0 0 1 -3.42 3.42h-17a3.41 3.41 0 0 1 -3.42-3.42v-31.09c0-4.64 1.36-20.32-12.13-20.32-10.45 0-12.58 10.73-13 15.55v35.86a3.42 3.42 0 0 1 -3.37 3.42h-16.42a3.41 3.41 0 0 1 -3.41-3.42v-66.87a3.41 3.41 0 0 1 3.41-3.42h16.42a3.42 3.42 0 0 1 3.42 3.42v5.78c3.88-5.82 9.63-10.31 21.9-10.31 27.18 0 27.02 25.38 27.02 39.32z"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </a>
          </li>
        </ul>
      </div>

      <div class="footer__heart">
        <p>Made with <span class="heart"></span></p>
      </div>
    </footer>
  </div>
</template>

<script>
// const headerImages = {
//   home: require("@/assets/images/playing-guitar.jpg"),
//   homeMobile: require("@/assets/images/playing-guitar-mobile.jpg"),
// };
// import gsap from "gsap";
import Spinner from "@/components/Spinner.vue";
export default {
  components: {
    Spinner,
  },
  data() {
    return {
      mobile: null,
      mobileNav: null,
      windowWidth: null,
      currentPage: "",
    };
  },

  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
    // console.log(this.$route.path);
    // this.$store.state.currentUrl = this.$router.name
    // this.currentPage = this.$store.state.currentUrl;
    // console.log(this.currentPage);
  },

  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },


    navFade(e) {
      if(e.target.classList.contains('navbar__link')) {
    console.log('test');
    const link = e.target;
    const siblings = link.closest('.navbar').querySelectorAll('.navbar__link');

    siblings.forEach(el => {
      if(el !== link) el.style.opacity = 0.5;
    })
  }

    },

    navFullColor(e) {
    const link = e.target;
    const siblings = document.querySelectorAll('.navbar__link');
    siblings.forEach(el => {
      if(el !== link) el.style.opacity = 1;
    })
  },

    contact() {
      // this.$router.push
      this.$router.push({ path: "/contact" });
      console.log("contact");
    },

    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 515) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
  },
  // computed: {
  //   images() {
  //     return headerImages;
  //   },
  // },
  // 


//   nav.addEventListener('mouseover', function(e) {
//   if(e.target.classList.contains('nav__link')) {
//     const link = e.target;
//     const siblings = link.closest('.nav').querySelectorAll('.nav__link');
//     const logo = link.closest('.nav').querySelector('img')

//     siblings.forEach(el => {
//       if(el !== link) el.style.opacity = 0.5;
//     })
//     logo.style.opacity = 0.5;
//   }
// })

};
// const firstName = document.querySelector('.my-name');
// const headshot = document.querySelector('.headshot-image');
// headshot.addEventListener('click', function() {
//   // firstName.classList.add('active');
//   console.log(firstName);
// }
// );
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400&family=Raleway:wght@400;600&display=swap");

// @charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x: 15px !default;
$hamburger-padding-y: 15px !default;
$hamburger-layer-width: 40px !default;
$hamburger-layer-height: 4px !default;
$hamburger-layer-spacing: 6px !default;
$hamburger-layer-color: #000 !default;
$hamburger-layer-border-radius: 4px !default;
$hamburger-hover-opacity: 0.7 !default;
$hamburger-active-layer-color: $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (collapse) !default;

// Base Hamburger (We need this)
// ==================================================

// Hamburger
// ==================================================
.hamburger {
  position: absolute;
  background: none;
  right: 5px;
  top: 5px;
  z-index: 2000;

  padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover,
  &:focus,
  &:active {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    } @else {
      opacity: $hamburger-hover-opacity;
      background: none;
      outline: none;
    }
  }

  &.is-active {
    &:hover {
      @if $hamburger-hover-use-filter == true {
        filter: $hamburger-active-hover-filter;
      } @else {
        opacity: $hamburger-active-hover-opacity;
      }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $hamburger-active-layer-color;
      background-color: #fff;
      opacity: 1;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

@if index($hamburger-types, collapse) {
  /*
   * Collapse
   */
  .hamburger--collapse {
    .hamburger-inner {
      top: auto;
      bottom: 0;
      transition-duration: 0.13s;
      transition-delay: 0.13s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::after {
        top: ($hamburger-layer-spacing * 2 + $hamburger-layer-height * 2) * -1;
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
          opacity 0.1s linear;
      }

      &::before {
        transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
          transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &.is-active {
      .hamburger-inner {
        transform: translate3d(
            0,
            ($hamburger-layer-spacing + $hamburger-layer-height) * -1,
            0
          )
          rotate(-45deg);
        transition-delay: 0.22s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::after {
          top: 0;
          opacity: 0;
          transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
            opacity 0.1s 0.22s linear;
        }

        &::before {
          top: 0;
          transform: rotate(-90deg);
          transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
            transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
}

// font-family: "Poppins", sans-serif;
* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

p,
li {
  font-size: 2.5rem;
}

body {
  font-family: "Josefin Sans", sans-serif;
  line-height: 1.4;
  color: #1b1e1f;
  font-size: 2rem;
}

h1,
h2,
h3 {
  font-family: "Raleway", sans-serif;
}

button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  background: linear-gradient(
    to bottom right,
    rgb(107, 63, 136),
    rgb(48, 108, 133) 45%,
    rgb(0, 25, 89) 70%,
    rgb(107, 63, 136)
  );
  color: #ffffff;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 2.8rem;
  cursor: pointer;
  text-align: center;
  transition: background 350ms ease-in-out, transform 250ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button:hover,
button:focus {
  background: rgb(107, 63, 136);
  // color: #000;
}

button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

button:active {
  transform: scale(0.99);
}

#app {
  position: relative;
  min-height: 100vh;
  // height: 100vh;
  font-family: "Josefin Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1b1e1f;
}
/*******************************NAVIGATION *******************/
#nav {
  // position: fixed;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  background-color: rgba(4, 67, 98, 0.4);
  color: #fff;
  padding: 15px;

  // a {
  //   text-decoration: none;
  //   margin-right: 7rem;
  //   font-weight: 400;
  //   color: #fff;
  //   transition: all 0.25s ease-in;

  //   &.router-link-exact-active {
  //     text-decoration: underline;
  //     font-weight: bold;
  //   }
  // }
}

.navbar {
  width: 85%;
  margin: 0 auto;
  text-align: left;

  a {
    transition: all 0.25s ease-in;

    &.router-link-exact-active {
      text-decoration: underline;
      font-weight: bold;
    }
  }

  &__link {
    padding: 1rem;
    text-decoration: none;
    font-weight: 400;
    color: #fff;

    &:not(:last-child) {
      margin-right: 7rem;
    }
  }
}

.dropdown-nav {
  position: absolute;
  top: 0;
  right: 0;
  width: 52vw;
  height: 203px;
  background: #21465ae8;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  &__list-item {
    display: inline-block;
    margin: 0 auto;
    margin-left: 20%;
    margin-bottom: 1rem;
  }

  &__link {
    // color: #1b1e1f;
    color: #fff;
    padding: 1.5rem;
    text-decoration: none;
    font-weight: 400;
  }
}

// .headshot__container-work {
//   transform: translateY(-200px);
// }
//********************************************************************************** */
/******  $HEADSHOT-IMAGE **************************** */
/********************************************************************************** */
.header-content-container {
  position: relative;
}
.headshot__container {
  z-index: 1;
  display: flex;
  align-items: end;
  position: absolute;
  // top: calc(80.5vh + 5vw);
  top: 81.5%;
  left: 5%;
}

.headshot-image {
  border-radius: 4px;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.16);
}

.content-container {
  max-width: 85.4%;
  margin: 0 auto;
}

.hire-btn {
  margin-left: 3rem;
  text-decoration: none;
  background-color: #6b3f88;
  box-shadow: 0px 0px 15px 7px rgba(255, 252, 252, 0.5);
  color: #fff;
  padding: 0.8rem 1.4rem;
  width: 223px;
  border-radius: 4px;
  font-size: 2.2rem;
  text-align: center;
}

.my-name {
  position: absolute;
  top: 260px;
  font-size: 4.5rem;
  font-weight: 300;
  color: #1b1e1f;
}

/********************************************************************************** */
/******  $BACKGROUND-IMAGES **************************** */
/********************************************************************************** */
// .home-bg-img {
//   background-image: linear-gradient(
//       to right bottom,
//       rgba(29, 236, 197, 0.3),
//       rgba(91, 14, 214, 0.3) 100%
//     ),
//     url("~@/assets/images/playing-guitar.jpg");
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: top left;
//   background-position: center calc(5% - 15px);
//   min-height: 85vh;
// }
//
.top-mask-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.mask-img {
  animation: change-hue-max 15000ms infinite;
}

.headerImage {
  max-height: 78.5vh;
  object-fit: cover;
  width: 100%;
}
.home__headerImage {
  // min-height: 100vh;
  object-position: center 5%;
}

.about__headerImage {
  object-position: center 75%;
}
.contact__headerImage {
  min-height: 100vh;
}
.work__headerImage {
  object-position: center 20%;
  animation: change-hue 10000ms infinite linear;
}

// .about-bg-img {
//   background-image: url("~@/assets/images/playing-guitar-inverted.jpg");
//   background-size: cover;
//   background-repeat: no-repeat;
//   min-height: 85vh;
//   max-height: 78.5vh;
//   object-fit: cover;
//   width: 100%;
//   object-position: center 75%;
//   transition: all 0.2s ease;
// }

// .work-bg-img {
//   background-image: url("~@/assets/images/sitting-by-tree.png");
//   background-size: cover;
//   background-repeat: no-repeat;
//   min-height: 85vh;
//   max-height: 78.5vh;
//   object-fit: cover;
//   width: 100%;
//   object-position: center 75%;
//   transition: all 0.2s ease;
// }

/********************************************************************************** */
/******  PAGE TRANSITIONS  & ANIMATIONS **************************** */
/********************************************************************************** */
@keyframes slide-up-enter {
  0% {
    transform: translateY(40px); /* start 10px down*/
    opacity: 0;
  }
  100% {
    transform: translateY(0); /* start 10px down*/
    opacity: 1;
  }
}

@keyframes change-hue-max {
  0% {
    filter: hue-rotate(0deg);
  }
  // 50% {
  //   // filter: hue-rotate(360deg);
  //   filter: hue-rotate(360deg);
  // }
  100% {
    filter: hue-rotate(360deg);
  }
}

// Animation
// .slide-up-enter {
//   transform: translateY(100px); /* start 10px down*/
//   opacity: 0;
// }

// .slide-up-enter-active {
//   transition: all 1.2s ease;
// }

// .fade-enter {
//   opacity: 0;
// }

// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 3.5s ease-out;
// }

// .fade-leave-to {
//   opacity: 0;
// }

.mobile-nav-enter-active,
.mobile-nav-leave-active {
  transition: 1s ease all;
}

.mobile-nav-enter-from,
.mobile-nav-leave-to {
  transform: translateX(+235px);
}

.mobile-nav-enter-to {
  transform: translateX(0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-up-enter {
  transform: translateY(100px);
  opacity: 0;
}

.slide-up-enter-active {
  transition: all 2.5s ease;
}

/********************************************************************************** */
/******  ABOUT DESIGN SECTION  **************************** */
/********************************************************************************** */

// .headshot__container {
//   animation: slide-up-enter .3s ease-in;
//   animation-delay: 1s;
//   animation-fill-mode: backwards;
// }

// .slide-up-move {
//     transition: transform 0.5s ease-out;
//   }

// .headshot-image {
//   position: absolute;
//   top: 66%;
//   left: 15%;
// }

// .my-name {
//   position: absolute;
//   background-color: #fff;
//   top: 89%;
//   left: 15%
// }

// .active, .list {
//   .my-name {
//     transform: rotate(90deg) scaleX(0.08);
//     top: 40% !important;
//   }
// }

/********************************************************************************** */
/******  $FOOTER  **************************** */
/********************************************************************************** */
.footer {
  position: relative;
  background-image: linear-gradient(
    to right,
    rgb(107, 63, 136),
    rgb(48, 108, 133) 25% 45%,
    rgb(0, 25, 89) 70%,
    rgb(48, 152, 156) 85%,
    rgb(107, 63, 136)
  );
  padding: 1rem;
  z-index: 100;
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;

  &__heart {
    font-family: "Raleway", sans-serif;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 60%);
  }

  &__social-container {
    margin-left: 14%;
  }

  &__social-ul {
    display: flex;
    list-style-type: none;
  }
  &__social {
    margin-right: 3rem;
  }
}
@keyframes beatingHeart {
  0% {
    transform: rotate(45deg);
  }
  90% {
    transform: scale(1.3) rotate(45deg);
  }
  100% {
    transform: scale(1.6) rotate(45deg);
  }
}

.footer .heart {
  @media screen and (max-width: 1040px) {
    // margin: 2rem;
  }
  margin: 0 1rem;
  display: inline-block;
  background-color: #ff0000;
  z-index: 1;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  transform: rotate(45deg);
  animation: beatingHeart 1s ease-out infinite;
  transition: all 0.5s;
}
.footer .heart::after,
.footer .heart::before {
  content: "";
  background-color: red;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100px;
  position: absolute;
}
.footer .heart::before {
  top: -50%;
  left: 0%;
}
.footer .heart::after {
  top: 0%;
  left: -50%;
}

@media screen and (max-width: 1015px) {
  .footer__heart {
    top: -9%;
    left: 70%;
  }
}

@media screen and (max-width: 545px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer__heart {
    position: relative;
    top: auto;
    left: auto;
    transform: translate(0, 0);
  }
  .footer__social-container {
    margin-left: 0;
  }
  .footer__social:last-child {
    margin-right: 0rem !important;
  }
}

/********************************************************************************** */
/******  $MODAL  **************************** */
/********************************************************************************** */
.hideHeadshot {
  // height: 0 !important;
  // width: 0 !important;
  transform: translateX(-600px);
  // z-index: -1000;
  opacity: 0 !important;
}
.hide {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  height: 0 !important;
  width: 0 !important;
  z-index: -1000;
  opacity: 0 !important;
}

.blurred {
  filter: blur(5px) opacity(30%);
  // background-color: #000;
}

.overflowHidden {
  overflow: hidden !important;
}

/********************************************************************************** */
/******  $SCROLLBAR  **************************** */
/********************************************************************************** */
::-webkit-scrollbar {
  width: 25px;
  @media screen and (max-width: 600px) {
    width: 10px;
  }
}
::-webkit-scrollbar-track {
  background-color: #d17c60;
  border: 4px solid #2e6277;
  // box-shadow: inset 0 0 2.5px 2px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(
    45deg,
    rgb(107, 63, 136),
    rgb(48, 108, 133) 45%,
    rgb(0, 25, 89) 70%,
    rgb(107, 63, 136)
  );
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(
    45deg,
    rgb(112, 78, 134),
    rgb(63, 112, 133) 45%,
    rgb(7, 30, 88) 70%,
    rgb(112, 78, 134)
  );
  border-radius: 10px;
}
/********************************************************************************** */
/******  $HEADSHOT-IMAGE  **************************** */
/********************************************************************************** */

.headshot-image-home {
  // transform: translate3d(0px, -55%, 0);
  border-radius: 1000px;
}
.hire-btn-home {
  //  transform: translate3d(-343px, -190px, 0);
}
.my-name-home {
  opacity: 0;
  visibility: hidden;
  //  transform: translate3d(180px, -250px, 0);
  // color: #fff;
  // text-shadow: rgb(21, 24, 18) 1px 0 10px;
  // font-weight: 600;
}

.invisible {
  opacity: 0 !important;
}

.headshot-work-image {
  opacity: 0;
  visibility: hidden;
  // transform: translate3d(50px, -250px, 0) scale(0.892);
  // transform-origin: right;
  // @media screen and (max-width: 1459px) {
  //   transform: translate3d(72.5px, -180px, 0) scale(0.892);
  // }
  // transform: translateY(-200px);
  // width: 223px;
  // transform: scale(1.5);
  // transform: rotate(45deg);
  // transform: scale(0.8);
}

.hire-work-btn {
  // transform: translate(-253px, -150px);
  transform: translate3d(-343px, -190px, 0);
  @media screen and (max-width: 1459px) {
    transform: translate3d(-200px, -120px, 0) scale(0.892);
  }
  @media screen and (max-width: 1019px) {
    padding: 1.4rem 1.4rem;
  }
  @media screen and (max-width: 777px) {
    transform: translate3d(-130px, -70px, 0) scale(0.892);
    margin: 0;
  }
  @media screen and (max-width: 470px) {
    transform: translate3d(-110px, -90px, 0) scale(0.892);
  }
  @media screen and (max-width: 465px) {
    transform: translate3d(-110px, -105px, 0) scale(0.892);
  }
  @media screen and (max-width: 371px) {
    transform: translate3d(-110px, -135px, 0) scale(0.892);
  }
  // transform: scale(1.5);
  // transform: rotate(45deg);
  // transform: scale(0.8);
}

.hire-contact-btn,
.my-name-contact {
  opacity: 0;
}

.my-name-work {
  transform: translate3d(180px, -250px, 0);
  color: #fff;
  text-shadow: rgb(21, 24, 18) 1px 0 10px;
  font-weight: 600;
  @media screen and (max-width: 1459px) {
    transform: translate3d(155px, -180px, 0);
  }
  @media screen and (max-width: 1019px) {
    transform: translate3d(155px, -170px, 0);
  }
  @media screen and (max-width: 777px) {
    transform: translate3d(100px, -110px, 0);
  }
  @media screen and (max-width: 470px) {
    transform: translate3d(130px, -130px, 0);
  }
  @media screen and (max-width: 371px) {
    transform: translate3d(2px, -133px, 0);
  }

  // @media screen and (max-width: 470px) {
  //   transform: translate3d(130px, -130px, 0);
  // }
  // @media screen and (max-width: 777px) {
  //   transform: translate3d(110px, -121px, 0);
  // }
}

// .my-name-contact {
//   opacity: 0;
// }
//
// .headshot-contact-container {
//   top: 0 !important;
//   left: 0 !important;
// }
// .headshot-contact-image {
//   // transform: translate3d(343px, -250px, 0) scale(0.7);
//   transform: translate3d(15vw, -250px, 0) scale(0.7);
//   border-radius: 1000px;
//   @media screen and (max-width: 1280px) {
//     transform: translate3d(8vw, -250px, 0) scale(0.7);
//   }
//   @media screen and (max-width: 1051px) {
//     transform: translate3d(3vw, -250px, 0) scale(0.7);
//   }
//   @media screen and (max-width: 853px) {
//     transform: translate3d(-3vw, -250px, 0) scale(0.7);
//   }
//   @media screen and (max-width: 690px) {
//     // transform: translate3d(35vw, -620px, 0) scale(0.7);
//     top: 50px;
//   }

//   // top: -200px;
//   // left: 300px;
//   // transition: all 2.5s ease;
// }

// .headshot-contact-image {
//   transform: scale(0.7);
//   border-radius: 1000px;
//   position: absolute;
//   top: 120px;
//   left: 300px;
// }
// .headshot__container-contact {
//   transition: all 0.75s ease;
// }
.headshot-image {
  transition: all 0.75s ease;
}
.hire-btn {
  transition: all 0.75s ease;
  // transition-delay: 3s;
}
.my-name {
  transition: all 0.75s ease;
}

// .headshot-contact-image {
//   position: absolute;
//   border-radius: 1000px;
//   top: -50vh;
//   left: 300px;
//   width: 150px;
//   // transition: all 2.5s ease;
// }

@media screen and (max-width: 1459px) {
  .about-content-container {
    max-width: 90%;
  }
  // .headshot-contact-image {
  //   top: 190px;
  //   left: 240px
  // }
  // .contact__form {
  //   width: 27%;
  // }
  .headshot-image {
    //   max-width: 14.5vw !important;
    //   min-width: 55px;
    width: 150px;
  }
  .hire-btn {
    width: 150px;
    // padding: 1.2rem 1.4rem;
  }
  .my-name {
    font-size: 3.5rem;
    top: 165px;
  }
  .headshot__container {
    left: 5.5%;
  }
  .about-hr {
    margin-top: 6rem !important;
  }
}

@media screen and (max-width: 1238px) {
  .about__section-heading {
    font-size: 4rem;
  }

  // .headshot-contact-image {
  //   top: 165px;
  //   left: 140px;
  // }
}

@media screen and (max-width: 1019px) {
  html {
    font-size: 50%;
  }
  .about-content-container {
    max-width: 90%;
  }
  .headshot__container {
    left: 3.5%;
  }
  .about__intro-text {
    width: 50% !important;
  }
  // .headshot-contact-image {
  //   top: 65px;
  //   left: 60px;
  // }
}

@media screen and (max-width: 913px) {
  .success__card,
  .error__card {
    width: 90%;
  }
}

@media screen and (max-width: 777px) {
  .headshot__container {
    left: 5%;
  }
  .headshot-image,
  .hire-btn {
    width: 111px;
  }

  .my-name {
    top: 118px;
    font-size: 3rem;
  }
  .about__intro-text {
    margin-top: 13.4rem !important;
    width: 100% !important;
  }
  .about__development,
  .about__design {
    width: 70%;
    margin: 0 auto;
  }
  .about__design {
    border-bottom: 5px solid black;
    margin-bottom: 5rem;
    padding-bottom: 7rem;
  }
}

@media screen and (max-width: 665px) {
  .about__development,
  .about__design {
    width: 85%;
  }
  .about__design {
    margin: 0 auto 5rem auto;
  }
}

@media screen and (max-width: 506px) {
  .success__card,
  .error__card {
    width: 92.5%;
  }
  .success__h1,
  .error__h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 465px) {
  .headshot__container {
    width: 90%;
  }
  .about__intro-text {
    margin-top: 16.4rem !important;
  }
  .about__development,
  .about__design {
    width: 95%;
    // margin: 0 auto;
  }
  // .about #design-svg {
  //   width: 89px;
  //   }
  .hire-btn {
    position: absolute;
    top: 78px;
    left: 108px;
  }
}
@media screen and (max-width: 371px) {
  html {
    font-size: 45%;
  }
  .about__development,
  .about__design {
    width: 100%;
    // margin: 0 auto;
  }

  .about__process-list {
    margin-left: 5rem;
  }
}

@media screen and (max-width: 317px) {
  .about__paragraph {
    font-size: 2.35rem;
  }
}
@media screen and (max-width: 259px) {
  .hire-btn {
    left: 96px;
  }
}

/********************************************************************************** */
/******  $MEDIA QUERIES **************************** */
/********************************************************************************** */
// @media screen and (max-width: 1462px) {
//   .about-content-container {
//     max-width: 95.4% !important;
//   }
//   .headshot__container {
//     left: 3.5%;
//   }
// }

// @media screen and (max-width: 1395px) {
//   .hire-btn {
//     width: 14.5vw;
//     padding: 1rem 1.4rem;
//   }
//   .my-name {
//     top: 206px;
//     font-size: calc(2.3rem + 1vw);
//     top: 16vw;
//   }
// }
.headshot__container-contact {
  // top: 61% !important;
  // display: none;
  // transform: translate3d(180px, -250px, 0);
}

.hire-btn-contact {
  opacity: 0;
  visibility: hidden;
  // z-index: -1110;
  // transform: translate3d(180px, -250px, 0);
}
</style>
