<template>
  <div>
    <metainfo>
      <template v-slot:title="{ content }">{{ `${content}` }}</template>
    </metainfo>
    <div class="home">
      <transition
        appear
        @before-enter="beforeEnter"
        @enter="enter"
        :css="false"
      >
        <HeaderText />
      </transition>
      <!-- <Spinner /> -->
      <p class="home-text">
        I perform user research, create content, design interfaces, and develop
        web-apps with JavaScript or Vue.js. I’m available for work. Contact me!
      </p>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import HeaderText from "@/components/HeaderText.vue";
import { useMeta } from "vue-meta";
// import Spinner from "@/components/Spinner.vue";

export default {
  name: "Home",
  components: {
    HeaderText,
    // Spinner,
  },

  data() {
    return {};
  },

  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "scale(0,0)";
    },
    enter(el, done) {
      gsap.to(el, {
        delay: 0.85,
        duration: 1,
        opacity: 1,
        scale: 1,
        ease: "bounce.out",
        onComplete: done,
      });
    },
  },

  mounted() {
    // const headshotContainer = document.querySelector(".headshot__container");
    const headshotImage = document.querySelector(".headshot-image");
    const hireButton = document.querySelector(".hire-btn");
    const myName = document.querySelector(".my-name");
    // const headshotImg = document.querySelector(".headshot-image");
    // const hireButton = document.querySelector(".hire-btn");
    // const myName = document.querySelector(".my-name");
    headshotImage.classList.add("headshot-image-home");
    hireButton.classList.add("hire-btn-home");
    myName.classList.add("my-name-home");
    // const headshotContainer = document.querySelector(".headshot__container");
    // const headshotImage = document.querySelector(".headshot-image");
    // const hireButton = document.querySelector(".hire-btn");
    // const myName = document.querySelector(".my-name");

    // if (headshotContainer.classList.contains("hideHeadshot")) {
    //   console.log(headshotContainer, "working");
    // }
    // hireButton.classList.add("hideHeadshot");
    // headshotContainer.classList.add("hideHeadshot");
    // headshotImage.classList.add("hideHeadshot");
    // myName.classList.add("hideHeadshot");

    /* 
    When the home page is loaded, then the Headshot should be hidden
    If an element contains a class, then remove it, and add your new class
    loop through each class and remove the hideHeadshot class
    
    */
  },
  beforeMount() {
    // const headshotContainer = document.querySelector(".headshot__container");
    // const headshotImage = document.querySelector(".headshot-image");
    // const hireButton = document.querySelector(".hire-btn");
    // const myName = document.querySelector(".my-name");
    // if (headshotContainer.classList.contains("hideHeadshot")) {
    //   console.log(headshotContainer, "working");
    // }
    // hireButton.classList.add("hideHeadshot");
    // headshotContainer.classList.add("hideHeadshot");
    // headshotImage.classList.add("hideHeadshot");
    // myName.classList.add("hideHeadshot");
    // console.log("beforemount");
    // const headshotContainer = document.querySelector(".headshot__container");
    // if ()
    // const headshotElements = document.querySelectorAll(".hideHeadshot1");
    // const headshotImage = document.querySelector(".headshot-image");
    // const hireButton = document.querySelector(".hire-btn");
    // const myName = document.querySelector(".my-name");
    // if (headshotContainer.classList.contains("hideHeadshot")) {
    //    headshotContainer.classList.remove("hideHeadshot");
    // } else {
    //   console.log(headshotContainer);
    // }
    // headshotElements.forEach((element) => console.log(element));
  },
  beforeUnmount() {
    const headshotImage = document.querySelector(".headshot-image");
    const hireButton = document.querySelector(".hire-btn");
    const myName = document.querySelector(".my-name");
    headshotImage.classList.remove("headshot-image-home");
    hireButton.classList.remove("hire-btn-home");
    myName.classList.remove("my-name-home");
    // const headshotContainer = document.querySelector(".headshot__container");
    // const headshotImage = document.querySelector(".headshot-image");
    // const hireButton = document.querySelector(".hire-btn");
    // const myName = document.querySelector(".my-name");
    // headshotContainer.classList.remove("hideHeadshot");
    // headshotImage.classList.remove("hideHeadshot");
    // hireButton.classList.remove("hideHeadshot");
    // myName.classList.remove("hideHeadshot");
  },
  setup() {
    useMeta({
      title: "Dehsen Ragavulu | Home",
      htmlAttrs: { lang: "en-ZA", amp: true },
      description: [
        {
          name: "description",
          content: "Dehsen Ragavulu - UI/UX Designer & Web Developer",
        },
      ],
    });
  },
};

/*
ANIMATION IDEAS
ADD INFINATE RANDOM MUSIC NOTES ON PAGE LOAD
INFINATE PICTURE COLOURS ON THE HOME SCREEN
*/
</script>

<style lang="scss" scoped>
.home-text {
  width: 50%;
  margin: 214px auto 0px auto;
  font-size: 3.5rem;
  padding-bottom: 150px;

  @media screen and (max-width: 1459px) {
    margin-top: 138px;
    font-size: 3rem;
  }
  @media screen and (max-width: 777px) {
    font-size: 2.75rem;
    margin-top: 107px;
    width: 70%;
  }
  @media screen and (max-width: 350px) {
    margin-top: 212px;
    font-size: 2.5rem;
    width: 80%;
  }
  @media screen and (max-width: 310px) {
    margin-top: 232px;
    font-size: 2.5rem;
    width: 80%;
  }
}
// .home {
//   background-image: linear-gradient(
//     to right bottom,
//     rgba(29, 236, 197, 0.3),
//     rgba(91, 14, 214, 0.3) 100%
//   );
//   // position: absolute;
//   // url("~@/assets/images/playing-guitar.jpg");
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: top left;
//   background-position: center calc(5% - 15px);
//   min-height: 100vh;

//   &__header-image {
//     min-height: 100vh;
//   }
// }

// @media screen and (max-width: 545px) {
//   .home {
//     background-image: linear-gradient(
//         to right bottom,
//         rgba(29, 236, 197, 0.3),
//         rgba(91, 14, 214, 0.3) 100%
//       ),
//       url("~@/assets/images/playing-guitar-mobile.jpg");
//     background-position: 28% calc(5% - 15px) !important;
//   }
// }
.headshot-container {
  opacity: 0;
}
</style>
