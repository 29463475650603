import { createStore } from "vuex";

export default createStore({
  state: {
    navigation: {
      openIcon: "#menu",
      closeIcon: "#cancel",
      mobileNavIsOpen: false,
      currentUrl: "",
    },

    projects: {
      projectList: [
        {
          img: "lifecoach.jpg",
          name: "Lifecoach Simone",
          description: "Remote lifecoach sessions",
          icons: [
            "photoshop-logo.svg",
            "Figma-logo.svg",
            "xd-logo.svg",
            "HTML5-logo.svg",
            "Sass_Logo.svg",
            "Bootstrap_logo.svg",
            "Javascript-logo.svg",
            "Vue.js-logo.svg",
          ],
          title: "Turning an idea into a complete product",
          modalImage: "lifecoach.jpg",
          technologies: [
            { Design: "Figma, Adobe Illustrator, Adobe Photoshop, Adobe XD" },
            { Development: "Hugo, SCSS, Bootstrap 4, JavaScript, Vue.js 3" },
          ],
          goal: "Simone is a life & fitness coach who wanted to offer a life-coaching product, but didn’t know where to start. I was tasked to assist her with her product & website development",
          stories: [
            {
              title: "Researching user / target-customer needs",
              body: "After performing user research, it became clear that Simone’s clients needed an easy life coaching plan that wouldn’t affect their busy lives too much. They also needed flexible coaching schedules & payment plans. ",
              image: "lifecoach4.jpg",
            },

            {
              title: "Product Development",
              body: "I worked with Simone’ to develop her product into a flexible, remote service. Her dynamic offering empowers her clients to choose from many digital mediums & physical settings when being coached.",
            },

            {
              title: "Content Creation",
              body: "My content creation process is based on questions asked by previous customers as well as the personas that are generated during the research phase. Our strategy for Simone’s website was to create easily digestible content that would encourage the user to contact Simone. We divided the content into sections and prioritised the sections according to our user research findings.",
              image: "lifecoach3.jpg",
            },

            {
              title: "Design",
              body: "Due to time & budget constraints, we chose a Hugo template. The template was modified to create a modern, tranquil look & feel. I also created a custom logo for her with a pastel background. During the development phase Simone mentioned that she would like me to add more client reviews whenever she needed. I decided to design a “reviews” component which I would develop using Vue.js.",
              image: "lifecoach5.jpg",
            },

            {
              title: "Development",
              body: 'The website was developed using HTML, Sass (SCSS), Bootstrap, and JavaScript. I developed the “reviews” section using Vue.js in order to make adding new reviews quick & easy. Currently I am re-developing the slider using vanilla javascript as the "slick-slider" plugin that was initially used has security vulnerabilities',
            },
          ],
          url: "https://www.lifecoach-simone.com",
          website: "lifecoach-simone.com",
        },

        {
          img: "premier_web.jpg",
          name: "Premier Web",
          description: "Website as a service",
          icons: [
            "photoshop-logo.svg",
            "xd-logo.svg",
            "HTML5-logo.svg",
            "Sass_Logo.svg",
            "Javascript-logo.svg",
            "firebase-logo.svg",
          ],
          title: "User research, UI design, & Development of Premierweb.co.za",
          modalImage: "premier-web__website.jpg",
          technologies: [
            { Design: "Adobe Illustrator, Adobe Photoshop, Adobe XD" },
            {
              Development:
                "HTML, SCSS(BEM), JavaScript, Google Firebase Firestore DB",
            },
          ],
          goal: "Build a performant, easily digestible website that will guide the client toward enquiring about Premier Web's packages",
          stories: [
            {
              title: "Researching user / target-customer needs",
              body: "User research showed me what the most important questions that the user would have when visiting premierweb.co.za. It also became clear that a one-pager was the way to go as it was possible to hold the users attention while scrolling one page. The key would be to design easily digestible content that would not seem overwhelming to read",
              image: "premier-web_user-experience-research.jpg",
            },

            {
              title: "Content Creation & Layout",
              body: "My content creation process is based on questions asked by previous customers as well as the personas that are generated during the research phase. Having a content-first approach means that the content is given priority before designing for mobile or desktop. Since you already have the actual content before you start designing, you can easily design experiences for both mobile and desktop users",
              image: "premier-web__content-creation_and_layout.jpg",
            },

            {
              title: "Design visual research",
              body: "I wanted to use SVG throughout the website due to it's tiny file size. It takes more time upfront to design & develop, but the reward is a much more performant website. I wanted to go for a very clean, airy website with minimal content.",
              image: "premier-web__mood-board.jpg",
            },

            {
              title: "Final Design",
              body: "Once I had the actual content and moodboard, it was quite easy to design the website. I designed the entire desktop website as well as partial android and iphone designs for the aspects that concerned me. When you have the content first, and have both mobile & desktop in mind while designing, you dont have to worry about desktop-first or mobile-first approaches. The design is naturally mobile first but caters very well for desktops",
              image: "premier-web__final-design.jpg",
            },

            {
              title: "Development",
              body: "The website was developed using HTML, SCSS(BEM), JavaScript, and Google Firebase for my contact form. I decided to use a CSS float layout as I wanted to try to build my own responsive CSS layout grid without using flexbox or CCS grid. I prefer writing my own CSS & JavaScript instead of using frameworks like bootstrap unnecessarily. The image below is the final Google Lighthouse score on desktop",
              image: "premier-web__lighthouse_score.jpg",
            },
          ],
          url: "https://www.premierweb.co.za",
          website: "premierweb.co.za",
        },

        {
          img: "travel_agency.jpg",
          name: "Travel Agency Concept",
          description: "My first UX, UI, and dev project",
          icons: [
            "photoshop-logo.svg",
            "xd-logo.svg",
            "HTML5-logo.svg",
            "Sass_Logo.svg",
            "Javascript-logo.svg",
          ],
          title: "User research, UI design, & Development of a travel concept",
          modalImage: "travel-agency__website.jpg",
          technologies: [
            { Design: "Adobe Illustrator, Adobe Photoshop, Adobe XD" },
            {
              Development:
                "HTML, SCSS(BEM), JavaScript, Google Firebase Firestore DB",
            },
          ],
          goal: 'Create a "light", "airy" website that caters to youth without alienating potential older users. ',
          stories: [
            {
              title: "Researching user / target-customer needs",
              body: "My main target audience were females performing entry-level administrative jobs, and single middle-aged women looking to have fun with similar holiday makers. I created personas based on my target demographics, psychographics, roles etc. I mapped out the user & client needs, website content & functionality requirements based on the user and client needs. One of the last stages is the preliminary site map. The site map changes as the content is mapped out an created",
              image: "travel-agency_user-experience-research.jpg",
            },

            {
              title: "Content Creation & Layout",
              body: 'As this was my first project, I did not have a content creation workflow and decided to create the content on paper. While trying to cater to both client and user needs, I came up with a process of cross referencing the two needs. When cross referencing, I decided to draw parallels between certain client and user needs. I would later use these parallels to "kill two birds with one stone". I would later prove that this process allows me to create interfaces that have minimal content, while still catering to both user and client needs extremely well! I advocate for minimal interfaces as too much info can place a large cognitive load on the user which could cause them to leave the website or browse through the content without reading much.',
              image: "travel-agency__content-creation_and_layout.jpg",
            },

            {
              title: "Final Design",
              body: 'The final design turned out to be clean and "airy". In hindsight, the "how it works" section should have been designed differently. The website does have the "feel" I was looking for. I think it caters to the target demographic. I tried to include older audiences by having a magazine-like feel in certain areas eg. the about page header image.',
              image: "travel-agency__final-design.jpg",
              image2: "travel-agency__final-design-about-page.jpg",
            },

            {
              title: "Development",
              body: "The website was developed using HTML, SCSS(BEM), and JavaScript. I built this a long time ago and the css and javascript could do with refactoring, but I learnt a lot on this project and I'm proud of it. I also managed to get quite a good Google lighthouse score!",
              image: "travel-agency__lighthouse.jpg",
            },
          ],
          url: "https://travel-fb0o.onrender.com.",
          website: "Visit Travel project",
        },

        {
          img: "travelling_thali.jpg",
          name: "Travelling Thali app design",
          description: "Indian vegetable platter delivery",
          icons: ["photoshop-logo.svg", "xd-logo.svg"],
          title: "An Indian food delivery service concept",
          modalImage: "travelling-thali.jpg",
          technologies: [{ Design: "Adobe Photoshop, Adobe XD" }],
          goal: "Design a prototype for a food delivery web app. I wanted to design a responsive web app that could be ported to Android & IOS without much design changes",
          stories: [
            {
              title: 'Creating a moodboard to get a "feel" of the idea',
              body: "Since thalis originated in South India, I wanted the design to be informed by it's South Indian heritage. I decided to draw my inspiration from rural South India",
              image: "travelling-thali-moodboard.jpg",
            },

            {
              title: 'Applying the "brand" to the app',
              body: 'I created a few samples to see which combination of the colour palette matched the "feel" I was looking for. I find that creating quick "home/landing" page designs can give me a feel for which design direction to go in.',
              image: "travelling-thali-brand_application.jpg",
            },

            {
              title: "Final responsive prototype",
              body: "Designing the final prototype is where all the fun is! I enjoy this part of the process the most because you can see the identity of the app taking shape",
              image: "travelling-thali-desktop_and_mobile.jpg",
            },

            {
              title: "Creating the rest of the views",
              body: "Because I had both desktop & mobile in mind when designing, I managed to end up with a design that works very well with both desktop web-apps & mobile apps. I designed the rest of the views for the food ordering & delivery process without needing to hide or change any content.",
              image: "travelling-thali-brand_application-desktop_views.jpg",
              image2: "travelling-thali-brand_application-mobile_views.jpg",
            },
          ],
          url: "",
          website: "",
        },

        {
          img: "dehsen.jpg",
          name: "Dehsen.com",
          description: "A website to house my projects",
          icons: [
            "photoshop-logo.svg",
            "xd-logo.svg",
            "HTML5-logo.svg",
            "Sass_Logo.svg",
            "Javascript-logo.svg",
            "Vue.js-logo.svg",
            "gsap-greensock.svg",
            "firebase-logo.svg",
          ],
          title: "User research, UI design, & Development of Dehsen.com",
          modalImage: "dehsen.com-website__designs.jpg",
          technologies: [
            { Design: "Adobe Illustrator, Adobe Photoshop, Adobe XD" },
            {
              Development:
                "HTML, SCSS(BEM), JavaScript, GSAP, Google Firebase Firestore",
            },
          ],
          goal: "Create a central place to house my projects to make it easy for recruiters/clients to see my work. Build a system that allows me to easily add new projects in minutes",
          stories: [
            {
              title: "Researching user / target-customer needs",
              body: "I used a very simple UX process to create this website. I created 3 personas and made sure that I met their needs when creating the content and design. ",
              image: "dehsen.com-user__research.jpg",
            },

            {
              title: "Content Creation & Layout",
              body: "I create my content in Adobe XD. It allows me to design based on the actual content.",
              image: "dehsen.com-content-creation_and_layout.jpg",
            },

            {
              title: "Design visual research",
              body: "I chose to go with a single header image throughout the website as it was the more performant option. I used css animations to show the transitioning between the various web-pages. Sometimes understanding web performance can lead to being creative in the way you design the interface. It also allows you to strike a balance between performance and design. Take a look at the below google lighthouse score of the website",
              image: "dehsen.com__lighthouse-score.jpg",
            },

            {
              title: "Development",
              body: "I built the website using Vue.js 3, GSAP, and Google Firebase for my hosting & contact form.",
            },
          ],
        },

        {
          img: "devise.jpg",
          name: "Design Agency Concept",
          description: "Brand Development & landing page",
          icons: ["xd-logo.svg"],
          title: "Developing a brand new brand",
          modalImage: "devise.png",
          technologies: [{ Design: "Adobe XD" }],
          goal: "Build a unique brand for a Digital Agency.",
          stories: [
            {
              title: "Ideation: Creating the company name",
              body: "I wanted to create a name based on the idealogical goals of the company. After creating the company history, I did a quick brainstorming session and came up with 3 potentional names.",
              image: "devise-ideation.jpg",
            },

            {
              title: "Visual research",
              body: "Mood boards are a quick & easy way for me to create reference material. The visual direction that I wanted to go in is based the svg-animated websites that many start-ups use. SVG based designs are great due to their small file size, infinite scalability, and modern browser support",
              image: "devise-moodboard.jpg",
            },

            {
              title: "Applying the brand",
              body: "Instead of creating a colour palette, I wanted to have the freedom to play around with colours while creating the landing page. The final landing page was a result of playing around with shapes & colours as shown in the below artboard",
              image: "devise-initial_design.jpg",
            },
          ],
        },

        {
          img: "LMS.jpg",
          name: "LMS Branding",
          description: "Branding, UX",
          icons: ["xd-logo.svg"],
          title: "Developing a new brand",
          modalImage: "learning-forest_website.jpg",
          technologies: [{ Design: "Adobe XD" }],
          goal: "Build a unique brand for a Learning Management System.",
          stories: [
            {
              title: "UX: Strategy & Information Architecture",
              body: "As an experiment, I wanted to try using UX to inform the branding. While creating the brand, I constantly reffered back to my UX doc, making sure that the brand was in line with the target user of the digital product. This wasn't really a waste of time since I was asked to do UX for the product anyway",
              image:
                "learning-forest_strategy_and_information_architecture.jpg",
            },

            {
              title: "Brand research",
              body: "The company name was formed by using the company philosophy as a reference point. After a quick brainstorm, I decided on Learning Forest. I used contemporary & historical vusual references by creating moodboards in Adobe XD.",
              image: "learning-forest_brand_development.jpg",
            },

            {
              title: "Logo",
              body: "I played around with various typefaces and decided on Trajan Pro for the logotype. The form of Trajan Pro resembles tree trunks and branches when set next to the logo image. I chose Futura as a secondary, preliminary body font",
              image: "learning-forest_logo.jpg",
            },
            {
              title: "Final logo & colour palette",
              body: "The Learning Management System was soon to be proposed to a representative of the Department of Education in the Limpopo province of South Africa. I chose colours that complimented their coat of arms. The colours were also earthy enough to be representative of the philosophy of the company. This is where doing the UX before the branding paid dividends.",
              image: "learning-forest_logo_&_colour_palette.jpg",
            },
          ],
        },

        // {
        //   img: "green_glacier.jpg",
        //   name: "Network Company Concept",
        //   description: "Design & landing page",
        //   icons: [
        //     "illustrator-logo.svg",
        //     "photoshop-logo.svg",
        //     "xd-logo.svg",
        //     "HTML5-logo.svg",
        //     "Sass_Logo.svg",
        //     "Javascript-logo.svg",
        //   ],
        // },

        // {
        //   img: "starter.jpg",
        //   name: "Starter Motor Repairs",
        //   description: "Starter Motors Repair Company",
        //   icons: [
        //     "illustrator-logo.svg",
        //     "xd-logo.svg",
        //     "HTML5-logo.svg",
        //     "Sass_Logo.svg",
        //     "Javascript-logo.svg",
        //     "Vue.js-logo.svg",
        //     "gsap-greensock.svg",
        //   ],
        // },
      ],
    },

    currentModalData: {
      title: "Turning an idea into a complete product",
      modalImage: "placeholder.jpg",
    },
    error: "",
    modalY: "",
    modelOpen: false,
  },
  mutations: {},
  actions: {},
  modules: {},
});
