<template>
  <div class="error">
    <div class="error__card">
      <h1 class="error__h1">An error occurred.</h1>
      <h3 class="error__h3">
        It could be due to many factors. Please try
        to submit the message again or later.
      </h3>
      <router-link :to="{ name: 'Contact' }"
        >Go to the contact page to try again</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    const headshot = document.querySelector(".headshot__container");
    console.log(headshot);
    headshot.classList.add("invisible");
  },
  beforeUnmount() {
    const headshot = document.querySelector(".headshot__container");
    console.log(headshot);
    headshot.classList.remove("invisible");
  },
};
</script>

<style lang="scss" scoped>
.error {
  background: linear-gradient(
    115deg,
    rgb(107, 63, 136),
    rgb(48, 108, 133) 40%,
    rgb(0, 25, 89) 70%,
    rgb(48, 152, 156) 85%,
    rgb(107, 63, 136)
  );
  height: 100vh;
  position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
}
.error__card {
  padding: 2rem;
  border-radius: 5px;
  background-color: #fff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //   color: #fff;
}
</style>
