<template>
  <div class="header-text">
    <h1 class="header-text__main">
      Hi there, I’m Dehsen<br />
      I’m a UI/UX Designer & A Front-end Web Developer
    </h1>
  </div>
</template>

<script>
export default {
  name: "HeaderText",
};
</script>

<style scoped lang="scss">
.header-text {
  background-image: linear-gradient(
    to right,
    rgb(107, 63, 136),
    rgb(48, 152, 156)
  );
  border-radius: 1px;
  width: 35%;
  text-align: left;
  padding: 0.55rem 0.55rem;
  color: #fff;
  position: absolute;
  left: 64%;
  top: 15%;
  z-index: 2;

  &__main {
    background-color: #343a3b;
    padding: 2rem 1.5rem;
    position: relative;
    color: #d5e1e6;
  }
}

@media screen and (max-width: 1140px) {
  .header-text {
    top: 60px;
  }
}
@media screen and (max-width: 1043px) {
  .header-text__main {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 606px) {
  .header-text {
    top: 223px;
    width: 55%;
    left: 165px;
  }
  .header-text__main {
    font-size: 2.5rem;
    padding: 0.3rem;
    line-height: 1.2;
  }
}

@media screen and (max-width: 553px) {
  .header-text {
    top: calc(190px - 5vw);
    // top: 210px;
    // font-size: 2.25rem;
    // width: 84.5%;
    // left: 7.75%;
    // transform: translate(-50%, 10px);
  }
  .header-text__main {
    font-size: 2.25rem;
  }
}

@media screen and (max-width: 460px) {
  .header-text {
    top: calc(150px - 5vw);
    width: 60%;
  }
  .header-text__main {
    font-size: 2rem;
  }
}

@media screen and (max-width: 419px) {
  .header-text {
    top: calc(138px - 5vw);
    left: 138px;
  }
}
@media screen and (max-width: 350px) {
  .header-text {
    top: calc(305px);
    left: 50%;
    transform: translateX(-50%) !important;
    width: 90%;
  }
  .header-text__main {
    font-size: 2.35rem;
  }
}
@media screen and (max-width: 314px) {
  .header-text {
    top: calc(290px);
    left: 50%;
    transform: translateX(-50%) !important;
    width: 90%;
  }
  .header-text__main {
    font-size: 2.35rem;
  }
}
</style>
