<template>
  <div class="not-found">
      <h1 class="not-found__h1">Oops!</h1>
      <h3 class="not-found__h3">The page you're looking for is not here.</h3>
      <router-link :to="{name: 'Home'}">Back to the home page</router-link>
  </div>
   
</template>

<script>

</script>

<style lang="scss">
.not-found {
        text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
