<template>
  <div>
    <transition name="fade">
      <div v-if="show" class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "preloader",
  data() {
    return {
      show: true,
    };
  },

  mounted() {
    this.showToggle();
  },

  methods: {
    showToggle() {
      setTimeout(() => {
        this.show = false;
      }, 350);
    },
  },
};
</script>

<style lang="scss" scoped>
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  //   width: 100vw;
  //     height: 100vh;
  //     background-color: white;
  //     position: absolute;
  //     top: 0;
  //     z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 999;
}
.lds-ellipsis div {
  position: absolute;
  //   top: 33px;
  top: 50%;
  top: 300px;
  //   left: 50%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: rgb(7, 7, 7);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  left: calc(45% + 8px);
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: calc(45% + 8px);
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: calc(45% + 32px);
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: calc(45% + 56px);
  animation: lds-ellipsis3 0.6s infinite;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
